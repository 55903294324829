<!-- <robot-dashboard></robot-dashboard> -->

<div id="alabia-inner" class="page-layout carded fullwidth inner-scroll">
  <div class="header accent p-24">
    <mat-icon>assessment</mat-icon>
    <h1 class="logo-text">Gerenciamento</h1>
    <br />
  </div>

  <div class="header-range">
    <div class="mat-headline" id="title-dashboard">Visão Geral</div>

    <div id="form-field">
      <mat-form-field appearance="outline" color="accent" style="padding-right: 10px; width: 200px">
        <mat-label>Tipo de Atividade</mat-label>
        <mat-select (selectionChange)="filterByHumanOrRobot($event.value)">
          <mat-option value="MANUAL">Humano</mat-option>
          <mat-option value="AUTO">Robô</mat-option>
          <mat-option value="all">Todos</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" color="accent" style="padding-right: 10px; width: 200px">
        <mat-label>Local</mat-label>
        <mat-select (selectionChange)="filterByUnit($event.value)">
          <mat-option *ngFor="let unit of unitList" value="{{unit.id}}">{{unit.name}} </mat-option>
          <mat-option value="all">Todos</mat-option>

        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" color="accent" style="padding-right: 10px; width: 200px">
        <mat-label>Histórico</mat-label>
        <mat-select [(ngModel)]="historicView" (selectionChange)="historicViewChange($event.value)">
          <mat-option value="all">Análise trimestral</mat-option>
          <mat-option value="today">Análise do dia</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- date range -->
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Selecione o intervalo</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [min]="minDate" [max]="today">
          <input matStartDate formControlName="start" placeholder="Data inicial" />
          <input matEndDate formControlName="end" placeholder="Data Final" (dateChange)="interval()" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div id="subheading">
    <div class="mat-subheading-2" id="subtitle-dashboard">
      <span *ngIf="startDay && endDay" [ngStyle]="{
          display: startDay && endDay ? '' : 'none'
        }">
        {{
        startDay.toLocaleString("default", { day: "numeric" }) +
        " de " +
        startDay.toLocaleString("default", { month: "long" }) +
        " de " +
        startDay.toLocaleString("default", { year: "numeric" }) +
        " - "
        }}
        {{
        endDay.toLocaleString("default", { day: "numeric" }) +
        " de " +
        endDay.toLocaleString("default", { month: "long" }) +
        " de " +
        endDay.toLocaleString("default", { year: "numeric" })
        }}
      </span>
    </div>

    <div id="mapsForm">
      <mat-form-field appearance="outline" color="accent" style="padding-right: 10px; width: 200px">
        <mat-label>Tipo de limpeza</mat-label>
        <mat-select [(ngModel)]="historicView" (selectionChange)="filterByCleaningMode($event.value)">
          <mat-option *ngFor="let clean of cleaningModeListViewName" value="{{clean}}">{{clean}}</mat-option>
          <mat-option value="all">Todos</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" color="accent" style="padding-right: 10px; width: 200px">
        <mat-label>Membro</mat-label>
        <mat-select [(ngModel)]="memberView" (selectionChange)="loadIntervalData()" [matMenuTriggerFor]="menuMembro"
          multiple>
          <mat-menu #menuMembro="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="membersMenu">
              Equipe
            </button>
            <button mat-menu-item [matMenuTriggerFor]="machines">Robôs</button>
            <button mat-menu-item (click)="resetMemberView()">Todos</button>
          </mat-menu>

          <mat-menu #membersMenu="matMenu">
            <mat-option *ngFor="let member of membersFilter" [value]="member">{{
              member
              }}</mat-option>
          </mat-menu>

          <mat-menu #machines="matMenu">
            <mat-option *ngFor="let machine of dataMachine.data" [value]="machine">
              <span *ngIf="machine.name.length > 0">
                {{
                optionsMachine.indexOf(machine.name) != -1
                ? machineName[optionsMachine.indexOf(machine.name)]
                : machine.name
                }}
              </span>
            </mat-option>
          </mat-menu>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" color="accent" style="width: 250px">
        <mat-label>Mapas</mat-label>
        <mat-select [(ngModel)]="mapView" (selectionChange)="taskOption()" [matMenuTriggerFor]="menuMap">
          <mat-menu #menuMap="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="mapMenu">Mapas</button>
            <button mat-menu-item (click)="resetMapView()">Todos</button>
          </mat-menu>

          <mat-menu #mapMenu="matMenu">
            <mat-option *ngFor="let mapa of dataMap.data" [value]="mapa" style="width: 550px">{{ mapa.title
              }}</mat-option>
          </mat-menu>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" color="accent" style="width: 250px; padding-left: 10px"
        *ngIf="taksOptions.data.length > 0">
        <mat-label>Tarefas</mat-label>
        <mat-select [(ngModel)]="taskView" (selectionChange)="loadIntervalData()" [matMenuTriggerFor]="menuTask">
          <mat-menu #menuTask="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="taskMenu">
              Tarefas
            </button>
            <button mat-menu-item (click)="resetTaskView()">Todos</button>
          </mat-menu>

          <mat-menu #taskMenu="matMenu">
            <mat-option *ngFor="let task of taksOptions.data" [value]="task">{{
              task.name
              }}</mat-option>
          </mat-menu>
        </mat-select>
      </mat-form-field>


      <!-- <mat-form-field
        appearance="outline"
        color="accent"
        style="padding-right: 10px; width: 200px"
      >
        <mat-label>Membro</mat-label>
        <mat-select
          [(ngModel)]="memberView"
          (selectionChange)="loadIntervalData()"
          [matMenuTriggerFor]="menuMembro"
          multiple
        >
          <mat-menu #menuMembro="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="membersMenu">
              Equipe
            </button>
            <button mat-menu-item [matMenuTriggerFor]="machines">Robôs</button>
            <button mat-menu-item (click)="resetMemberView()">Todos</button>
          </mat-menu>

          <mat-menu #membersMenu="matMenu">
            <mat-option *ngFor="let member of membersFilter" [value]="member">{{
              member
            }}</mat-option>
          </mat-menu>

          <mat-menu #machines="matMenu">
            <mat-option
              *ngFor="let machine of dataMachine.data"
              [value]="machine"
            >
              <span *ngIf="machine.name.length > 0">
                {{
                  optionsMachine.indexOf(machine.name) != -1
                    ? machineName[optionsMachine.indexOf(machine.name)]
                    : machine.name
                }}
              </span>
            </mat-option>
          </mat-menu>
        </mat-select>
      </mat-form-field> -->
    </div>
  </div>

  <div class="cards ml-12 mr-12">
    <!-- Cards -->
    <div class="functionality mat-elevation-z2" id="{{ selectedTab == 'Áreas' ? selectedTab : null }}"
      (click)="changeTabIndex(0)">
      <div class="mat-title">Área de cobertura</div>
      <div class="description-layout">
        <div class="desc-column">
          <div class="mat-headline" style="color: #9a75fc">
            {{ zoneLenght.toFixed(1) | number }}
          </div>
          <div>m²</div>
        </div>
        <div class="divider"></div>
        <div class="desc-column">
          <div class="mat-headline" style="color: #1ea5fc">
            {{
            yAxisZone.length
            ? ((zoneLenght / yAxisZone.length).toFixed(1) | number)
            : 0
            }}
          </div>
          <div *ngIf="gap == 'mensal'">m²/mês</div>
          <div *ngIf="gap == 'diario'">m²/dia</div>
          <div *ngIf="gap == 'hoje'">m²/hora</div>
        </div>
        <div class="divider"></div>
        <div class="desc-column">
          <div class="mat-headline" style="color: #31d159">
            {{
            totalAreaLenght
            ? ((successRate).toFixed(2) | number)
            : 0
            }}%
          </div>
          <div>taxa de sucesso</div>
        </div>
      </div>
    </div>

    <div class="functionality mat-elevation-z2" id="{{ selectedTab == 'Tarefas' ? selectedTab : null }}"
      (click)="changeTabIndex(1)">
      <div class="mat-title">Tarefas</div>
      <div class="description-layout">
        <div class="desc-column">
          <div class="mat-headline" style="color: #9a75fc">
            {{ tasksLenght | number }}
          </div>
          <div>concluídas</div>
        </div>
        <div class="divider"></div>
        <div class="desc-column">
          <div class="mat-headline" style="color: #1ea5fc">
            {{
            yAxisTask.length
            ? ((tasksLenght / yAxisTask.length).toFixed(2) | number)
            : 0
            }}
          </div>
          <div *ngIf="gap == 'mensal'">média (mês)</div>
          <div *ngIf="gap == 'diario'">média (dia)</div>
          <div *ngIf="gap == 'hoje'">m²/hora</div>
        </div>
      </div>
    </div>

    <div class="functionality mat-elevation-z2" id="{{ selectedTab == 'Mapas' ? selectedTab : null }}"
      (click)="changeTabIndex(2)">
      <div class="mat-title">Mapas de limpeza</div>
      <div class="description-layout">
        <div class="desc-column">
          <div class="mat-headline" style="color: #9a75fc">
            {{ mapLenght | number }}
          </div>
          <div>concluídos</div>
        </div>
        <div class="divider"></div>
        <div class="desc-column">
          <div class="mat-headline" style="color: #9a75fc">
            {{
            yAxisMap.length
            ? ((mapLenght / yAxisMap.length).toFixed(1) | number)
            : 0
            }}
          </div>
          <div *ngIf="gap == 'mensal'">média (mês)</div>
          <div *ngIf="gap == 'diario'">média (dia)</div>
          <div *ngIf="gap == 'hoje'">média (hora)</div>
        </div>
      </div>
    </div>

    <div class="functionality mat-elevation-z2" id="{{ selectedTab == 'Tempo' ? selectedTab : null }}"
      (click)="changeTabIndex(3)">
      <div class="mat-title">Tempo de execução</div>
      <div class="description-layout">
        <div class="desc-column">
          <div class="mat-headline" style="color: #9a75fc">
            {{ timeLenght.toFixed(2)}}
          </div>
          <div>Horas</div>
        </div>
        <div class="divider"></div>
        <div class="desc-column">
          <div class="mat-headline" style="color: #1ea5fc">
            {{
            yAxisTime.length
            ? ((timeLenght / (60 * yAxisTime.length)).toFixed(2) | number)
            : 0
            }}
          </div>
          <div>duração/mês</div>
        </div>
      </div>
    </div>
    <div class="functionality mat-elevation-z2" id="{{ selectedTab == 'Modo' ? selectedTab : null }}"
      (click)="changeTabIndex(4)">
      <div class="mat-title">Tipos de Execução</div>
      <div class="description-layout">
        <div class="desc-column" *ngFor="let type of cleaningModeListViewName">
          <div class="mat-headline" style="color: #31d159">
            <div style="font-size:20px; color: #1ea5fc"> {{type}} </div>
            {{ loadNumberOfExecutions(type)}}
          </div>
          <div>{{percentage(type)}}</div>

        </div>
      </div>
    </div>
    <div class="functionality mat-elevation-z2" id="{{ selectedTab == 'Agendamentos' ? selectedTab : null }}"
      (click)="changeTabIndex(5)">
      <div class="mat-title">Agendamentos</div>
      <div class="description-layout">
        <div class="desc-column" >
          <div class="mat-headline" style="color: #9a75fc" *ngIf="scheduledLoad">
            {{ allTimedTasks.length - tasksScheduledNotExecuted.length  + "/"+ allTimedTasks.length}}
          </div>
          <mat-progress-bar mode="indeterminate" *ngIf="!scheduledLoad"></mat-progress-bar>
          <div>Agendamentos</div>
          <div style="font-size:20px; color: #1ea5fc" *ngIf="scheduledLoad">{{ ((allTimedTasks.length - tasksScheduledNotExecuted.length) / allTimedTasks.length * 100).toFixed(2) + '%'}}</div>
          <mat-progress-bar mode="indeterminate" *ngIf="!scheduledLoad"></mat-progress-bar>
        </div>


      </div>
    </div>
  </div>

  <!-- tabs -->
  <mat-tab-group class="mat-elevation-z2 ml-16 mr-16 mt-12" color="accent" (selectedTabChange)="changeTab($event)"
    [selectedIndex]="selectedIndex">
    <!-- tab areas -->
    <mat-tab label="Áreas">
      <mat-card>
        <ng-template matTabContent>
          <mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="accent"></mat-progress-bar>
          <div *ngIf="!isLoading" style="display: block; height: 50px; text-align: end; padding: 8px">
            <mat-button-toggle-group style="text-align: end" [(ngModel)]="gap" aria-label="Font Style"
              (change)="dataView()">
              <mat-button-toggle *ngIf="axisXmonth.length > 1" value="mensal">Mensal</mat-button-toggle>
              <mat-button-toggle *ngIf="this.gap != 'hoje'" value="diario">Diário</mat-button-toggle>
              <mat-button-toggle *ngIf="this.gap == 'hoje'" value="hoje">Hoje</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div style="display: block" *ngIf="!isLoading">
            <canvas baseChart height="100" [datasets]="barChartDataZone" [labels]="barChartLabels"
              [options]="lineChartConfig" [legend]="barChartLegend" [chartType]="barChartType"
              maintainAspectRatio="true">
            </canvas>
          </div>
          <mat-divider></mat-divider>
          <div style="padding: 8px; text-align: end; min-height: 50px">
            <!-- botao para ir para a pagina mais detalhada -->
            <!-- <a mat-raised-button color="accent" [routerLink]="['zone']"
              >Visualizar</a
            > -->
          </div>
        </ng-template>
      </mat-card>
    </mat-tab>

    <!-- tab tarefas -->
    <mat-tab label="Tarefas">
      <mat-card>
        <ng-template matTabContent>
          <mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="accent"></mat-progress-bar>
          <div *ngIf="!isLoading" style="display: block; height: 50px; text-align: end; padding: 8px">
            <mat-button-toggle-group style="text-align: end" [(ngModel)]="gap" aria-label="Font Style"
              (change)="dataView()">
              <mat-button-toggle *ngIf="axisXmonth.length > 1" value="mensal">Mensal</mat-button-toggle>
              <mat-button-toggle *ngIf="this.gap != 'hoje'" value="diario">Diário</mat-button-toggle>
              <mat-button-toggle *ngIf="this.gap == 'hoje'" value="hoje">Hoje</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div style="display: block" *ngIf="!isLoading">
            <canvas baseChart height="100" [datasets]="barChartDataTask" [labels]="barChartLabels"
              [options]="barChartOptions" [legend]="barChartLegend" [options]="lineChartConfig"
              [chartType]="barChartType">
            </canvas>
          </div>
          <mat-divider></mat-divider>
          <div style="padding: 8px; text-align: end; min-height: 50px">
            <!-- botao para ir para a pagina mais detalhada -->
            <!-- <a mat-raised-button color="accent" [routerLink]="['zone']"
              >Visualizar</a
            > -->
          </div>
        </ng-template>
      </mat-card>
    </mat-tab>
    <mat-tab label="Mapas">
      <mat-card>
        <ng-template matTabContent>
          <mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="accent"></mat-progress-bar>
          <div *ngIf="!isLoading" style="display: block; height: 50px; text-align: end; padding: 8px">
            <mat-button-toggle-group style="text-align: end" [(ngModel)]="gap" aria-label="Font Style"
              (change)="dataView()">
              <mat-button-toggle *ngIf="axisXmonth.length > 1" value="mensal">Mensal</mat-button-toggle>
              <mat-button-toggle *ngIf="this.gap != 'hoje'" value="diario">Diário</mat-button-toggle>
              <mat-button-toggle *ngIf="this.gap == 'hoje'" value="hoje">Hoje</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div style="display: block" *ngIf="!isLoading">
            <canvas baseChart [datasets]="barChartDataMap" height="100" [labels]="barChartLabels"
              [options]="barChartOptions" [legend]="barChartLegend" [options]="lineChartConfig"
              [chartType]="barChartType">
            </canvas>
          </div>
          <mat-divider></mat-divider>
          <div style="padding: 8px; text-align: end; min-height: 50px">
            <!-- botao para ir para a pagina mais detalhada -->
            <!-- <a mat-raised-button color="accent" [routerLink]="['zone']"
              >Visualizar</a
            > -->
          </div>
        </ng-template>
      </mat-card>
    </mat-tab>
    <mat-tab label="Tempo">
      <mat-card>
        <ng-template matTabContent>
          <mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="accent"></mat-progress-bar>
          <div *ngIf="!isLoading" style="display: block; height: 50px; text-align: end; padding: 8px">
            <mat-button-toggle-group style="text-align: end" [(ngModel)]="gap" aria-label="Font Style"
              (change)="dataView()">
              <mat-button-toggle *ngIf="axisXmonth.length > 1" value="mensal">Mensal</mat-button-toggle>
              <mat-button-toggle *ngIf="this.gap != 'hoje'" value="diario">Diário</mat-button-toggle>
              <mat-button-toggle *ngIf="this.gap == 'hoje'" value="hoje">Hoje</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div style="display: block" *ngIf="!isLoading">
            <canvas baseChart [datasets]="barChartDataTime" height="100" [labels]="barChartLabels"
              [options]="barChartOptions" [legend]="barChartLegend" [options]="lineChartConfig"
              [chartType]="barChartType">
            </canvas>
          </div>
          <mat-divider></mat-divider>
          <div style="padding: 8px; text-align: end; min-height: 50px">
            <!-- botao para ir para a pagina mais detalhada -->
            <!-- <a mat-raised-button color="accent" [routerLink]="['zone']"
              >Visualizar</a
            > -->
          </div>
        </ng-template>
      </mat-card>
    </mat-tab>
    <mat-tab label="Modo">
      <mat-card>
        <ng-template matTabContent>
          <mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="accent"></mat-progress-bar>
          <div *ngIf="!isLoading" style="display: block; height: 50px; text-align: end; padding: 8px">
            <mat-button-toggle-group style="text-align: end" [(ngModel)]="gap" aria-label="Font Style"
              (change)="dataView()">
              <mat-button-toggle *ngIf="axisXmonth.length > 1" value="mensal">Mensal</mat-button-toggle>
              <mat-button-toggle *ngIf="this.gap != 'hoje'" value="diario">Diário</mat-button-toggle>
              <mat-button-toggle *ngIf="this.gap == 'hoje'" value="hoje">Hoje</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div style="display: block" *ngIf="!isLoading">
            <canvas #chartPrint height="100" *ngIf="!isLoading" baseChart [datasets]="barChartCleanMode "
              [labels]="cleaningModeListViewName" [options]="lineChartConfig" chartType="doughnut"
              [ngStyle]="expandList ? { width: '70vw' } : { width: '45vw' }">
            </canvas>
          </div>
          <mat-divider></mat-divider>
          <div style="padding: 8px; text-align: end; min-height: 50px">
            <!-- botao para ir para a pagina mais detalhada -->
            <!-- <a mat-raised-button color="accent" [routerLink]="['zone']"
              >Visualizar</a
            > -->
          </div>
        </ng-template>
      </mat-card>
    </mat-tab>

  <mat-tab label="Agendamentos">

    <mat-card>
      <ng-template matTabContent>
        <mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="accent"></mat-progress-bar>
        <div *ngIf="!isLoading" style="display: block; height: 50px; text-align: end; padding: 8px">
          <mat-button-toggle-group style="text-align: end" [(ngModel)]="gap" aria-label="Font Style"
            (change)="dataView()">
            <mat-button-toggle *ngIf="axisXmonth.length > 1" value="mensal" (click)="chartTypeMonth()">Barra</mat-button-toggle>
            <mat-button-toggle *ngIf="this.gap != 'hoje'" value="diario" (click)="chartTypeDay()">Linha</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div style="display: block" *ngIf="!isLoading">
          <canvas #chartPrint height="100" *ngIf="!isLoading" baseChart [datasets]="barChartDataUnit "
            [labels]="monthList" [options]="barChartConfig" [chartType]="scheduledChartType"
            [ngStyle]="expandList ? { width: '70vw' } : { width: '45vw' }">
          </canvas>
        </div>
        <mat-divider></mat-divider>
        <div style="padding: 8px; text-align: end; min-height: 50px">
          <!-- botao para ir para a pagina mais detalhada -->
          <!-- <a mat-raised-button color="accent" [routerLink]="['zone']"
            >Visualizar</a
          > -->
        </div>
      </ng-template>
    </mat-card>
  </mat-tab>
  </mat-tab-group>
  <div id="inf-cards-layout" class="m-12 mt-16" [ngStyle]="{
      'grid-template-columns': expandList
        ? 'none'
        : 'repeat(auto-fill, minmax(450px, 1fr))'
    }">
    <div class="card-inf mat-elevation-z2">
      <div class="title-inf mat-subheading-1">
        <button mat-icon-button disabled>
          <mat-icon color="black"> list </mat-icon>
        </button>
        <span>Lista</span>
        <div id="expand-icon" style="text-align: end; margin: 0px 16px 0 auto">
          <button mat-icon-button (click)="expandTable()">
            <mat-icon *ngIf="!this.expandList">fullscreen</mat-icon>
            <mat-icon *ngIf="this.expandList">minimize</mat-icon>
          </button>
        </div>
      </div>

      <!-- <mat-spinner
        style="margin: 10px auto 10px auto"
        color="accent"
        *ngIf="isLoading"
      ></mat-spinner> -->

      <mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="accent"></mat-progress-bar>

      <div style="padding-left: 20px" *ngIf="!isLoading">
        Lista com as últimas atividades de limpeza
      </div>

      <table mat-table [dataSource]="dataExecutionSlice" matSort matSortActive="start" matSortDirection="desc"
        [ngStyle]="{
          display: isLoading ? 'none' : ''
        }">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="ordem" [hidden]="!expandList">
            Nº
          </th>
          <td mat-cell *matCellDef="let item" [hidden]="!expandList">
            {{ item.id }}
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef style="padding-left: 10px">
            Nome da Tarefa
          </th>
          <td mat-cell *matCellDef="let item" style="padding-left: 10px">
            {{
            item.data.name
            ? item.data.name.replace("execute_task_", "")
            : item.task.name.replace("execute_task_", "")
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="mapName">
          <th mat-header-cell *matHeaderCellDef>Mapa</th>
          <td mat-cell *matCellDef="let item">
            {{ item.data.mapName ? item.data.mapName : item.task.map.title }}
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef [hidden]="!expandList">Tipo</th>
          <td mat-cell *matCellDef="let item" [hidden]="!expandList">
            {{ item.trigger == trigger ? "Manual" : "Robô" }}
          </td>
        </ng-container> -->

        <ng-container matColumnDef="model">
          <th mat-header-cell *matHeaderCellDef [hidden]="!expandList">Robô</th>
          <td mat-cell *matCellDef="let item" [hidden]="!expandList">
            {{ item.trigger == trigger ? "Manual" : item.machine.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="duration">
          <th mat-header-cell *matHeaderCellDef [hidden]="!expandList">
            Duração
          </th>
          <td mat-cell *matCellDef="let item" [hidden]="!expandList">
            {{ (item.duration / 60).toFixed(2) }}min
          </td>
        </ng-container>

        <ng-container matColumnDef="area">
          <th mat-header-cell *matHeaderCellDef [hidden]="!expandList">
            Área limpa
          </th>
          <td mat-cell *matCellDef="let item" [hidden]="!expandList">
            {{
            item.data.coverageArea
            ? item.data.coverageArea.toFixed(2) + "m"
            : "---"
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="operator">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Membro">
            Membro
          </th>
          <td mat-cell *matCellDef="let item">{{ item.operator }}</td>
        </ng-container>

        <ng-container matColumnDef="start">
          <!-- [hidden]="!expandList" -->
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Início">
            Data
          </th>
          <td mat-cell *matCellDef="let item">
            {{ item.start | date: "medium" }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let item; columns: columnsToDisplay; let i = index"></tr>
      </table>
      <mat-paginator #paginator [pageSize]="6" [pageSizeOptions]="[6, 12, 24, 100]" showFirstLastButtons [ngStyle]="{
          display: isLoading ? 'none' : 'block'
        }">
      </mat-paginator>
    </div>

    <div class="card-inf mat-elevation-z2">
      <div class="title-inf mat-subheading-1">
        <button mat-icon-button disabled>
          <mat-icon> show_chart </mat-icon>
        </button>
        <span> Gráfico dos colaboradores por {{ selectedTab }} </span>
      </div>
      <mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="accent"></mat-progress-bar>
      <!-- zone -->
      <div style="display: block" *ngIf="selectedTab == 'Áreas'">
        <canvas #chartPrint height="190" *ngIf="!isLoading" baseChart [datasets]="barChartDataMemberZone"
          [labels]="barChartLabelsMember" [options]="lineChartConfig" chartType="horizontalBar"
          [ngStyle]="expandList ? { width: '70vw' } : { width: '45vw' }">
        </canvas>
        <!-- width: 751px;
    height: 375px;
    display: block; -->
      </div>
      <!-- tasks -->
      <div style="display: block" *ngIf="selectedTab == 'Tarefas'">
        <canvas height="190" *ngIf="!isLoading" baseChart [datasets]="barChartDataMemberTask"
          [labels]="barChartLabelsMember" [options]="lineChartConfig" chartType="horizontalBar"
          [ngStyle]="expandList ? { width: '70vw' } : { width: '45vw' }">
        </canvas>
      </div>
      <!-- maps -->
      <div style="display: block" *ngIf="selectedTab == 'Mapas'">
        <canvas height="200" *ngIf="!isLoading" baseChart [datasets]="barChartDataMemberMaps"
          [labels]="barChartLabelsMember" [options]="lineChartConfig" chartType="horizontalBar"
          [ngStyle]="expandList ? { width: '70vw' } : { width: '45vw' }">
        </canvas>
      </div>
      <!-- time -->
      <div style="display: block" *ngIf="selectedTab == 'Tempo'">
        <canvas height="200" *ngIf="!isLoading" baseChart [datasets]="barChartDataMemberTime"
          [labels]="barChartLabelsMember" [options]="lineChartConfig" chartType="horizontalBar"
          [ngStyle]="expandList ? { width: '70vw' } : { width: '45vw' }">
        </canvas>
      </div>
      <div style="display: block" *ngIf="selectedTab == 'Modo'">
        <canvas height="200" *ngIf="!isLoading" baseChart [datasets]="barChartCleanMode" style="display: none"
          [labels]="barChartLabelsMember" [options]="lineChartConfig" chartType="horizontalBar"
          [ngStyle]="expandList ? { width: '70vw' } : { width: '45vw' }">
        </canvas>
      </div>
      <div style="display: block" *ngIf="selectedTab == 'Agendamentos'">
        <canvas height="200" *ngIf="!isLoading" baseChart [datasets]="barChartCleanMode" style="display: none"
          [labels]="barChartLabelsMember" [options]="lineChartConfig" chartType="horizontalBar"
          [ngStyle]="expandList ? { width: '70vw' } : { width: '45vw' }">
        </canvas>
      </div>
    </div>
  </div>
</div>
