import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ChecklistExecutionService, ChecklistItemService, MachineExecution, MachineExecutionService, MachineMap, MachineMapService, MachineScheduler, MachineSchedulerService, MachineTask, MachineTaskService, SchedulerInfoService, TimedTask, TriggerType, UserService } from 'onevoice';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { stat } from 'fs';

@Component({
  selector: 'main-my-manual-tasks',
  templateUrl: './my-manual-tasks.component.html',
  styleUrls: ['./my-manual-tasks.component.scss']
})
export class MyManualTasksComponent implements OnInit {
  public toDate(timestamp: number) {
    return new Date(timestamp)
  }


  public currenteDate: Date = new Date();
  public task?: MachineTask;
  public taskList: MatTableDataSource<MachineTask> = new MatTableDataSource<MachineTask>();
  public scheduler: MachineScheduler[] = [];

  public tasksToday: MatTableDataSource<TimedTask> = new MatTableDataSource<TimedTask>();
  public machineExecuted: MatTableDataSource<MachineExecution> = new MatTableDataSource<MachineExecution>();
  public executedTasks: TimedTask[] = [];
  public machineMaps: MachineMap[] = [];
  public dataLoaded: boolean = false;
  public finished: boolean = false;
  public path: String = ''
  public duration: number = 0;

  public load: boolean = true;
  public fields: string[] = [
    "checked",
    "name",
    "type",
    "start",
    "map",
    "tarefa",
    "actions",
  ];
  public executedFields: string[] = [

    "name",
    "map",
    "operator",
    "start"

  ];
  public backupData: TimedTask[] = [];


  constructor(

    public tasks: MachineTaskService,
    public schedulerService: MachineTaskService,
    public machineScheduler: MachineSchedulerService,
    public dialog: MatDialog,
    public timedTaskInfo: SchedulerInfoService,
    public users: UserService,
    private _route: ActivatedRoute,
    private maps: MachineMapService,
    private executions: MachineExecutionService,
    private checklists: ChecklistItemService,
    private checkExecutions: ChecklistExecutionService,



  ) { }

  userProfile() {
    this.users.info().subscribe(data => {

      const isAdmin = data.authorities.some(authority => authority.authorization === "ADMIN");
      if (isAdmin) {
        this.path = "cleaning/tasks/bymanger"
      }
    })
  }
  setScheduleInfo(timedTask: TimedTask) {

    this.timedTaskInfo.atualizarObjeto(timedTask);
  }
  public getMaps() {

    this.tasksToday.data.forEach(task => {
      this.machineMaps.push(task.machineTask.map)
    })
    this.machineMaps = this.machineMaps.filter((obj, index, self) =>
      index === self.findIndex((t) => t.id === obj.id)
    )
    // this.maps.list().subscribe(maps =>{
    //   this.machineMaps = maps.filter(map => map.type == "MANUAL");
    //   console.log(maps)
    // })
  }
  public create() {
    this.dataLoaded = true;

    this.executedTasks.forEach(exec => {
      this.duration = 0;
      this.checklists.getCheckListByIdTask(exec.machineTask.id).subscribe(checkList => {
        checkList.forEach(item => {
          console.log(item.duration)
          this.duration += item.duration;
        })

        this.executions.create({
          task: exec.machineTask,
          duration: this.duration,
          start: this.hourOfExecution(exec.startTime),
          end: this.hourOfExecution(exec.startTime) + (this.duration  * 1000) ,
          completeness: 100.0,
          trigger: TriggerType.MANUAL,
        }).subscribe(created => {
          this.machineExecuted.data.push(created);
          this.checklists.getCheckListByIdTask(exec.machineTask.id).subscribe(checkList => {
            checkList.forEach(item => {
              this.checkExecutions.create({
                checked: true,
                execution: created,
                item: item,
              }).subscribe(checkListExecutionCreated => {

              });
            })
            console.log(created);
            this.finished = true;
          })
        })
      })
    })
  }

  public hourOfExecution(horario: string) {
    const today = new Date();

    const [hours, minutes, sec] = horario.split(":").map(Number);

    today.setHours(hours, minutes, sec, 0);

    return today.getTime();
  }
  public hourOfEndExecution(horario: string) {
    const today = new Date();

    const [hours, minutes, sec] = horario.split(":").map(Number);

    today.setHours(hours, minutes + 10, sec, 0);

    return today.getTime();
  }
  public filterByMap(mapId: number) {

    this.tasksToday.data = this.backupData;
    if (mapId == 0) {
      this.tasksToday.data = this.backupData;
      this.executedTasks = this.tasksToday.data;

      return
    }

    this.tasksToday.data = this.tasksToday.data.filter(task => task.machineTask.map.id == mapId);
    this.executedTasks = this.tasksToday.data;


  }


  public ngOnInit(): void {
    this.userProfile()

    this.machineScheduler.listByUser().subscribe(scheduler => {
      this.todayTask(scheduler);
      this.dataLoaded = true;
      this.finished = false;
    })


    this._route.params.subscribe((param: Params) => {
      let pk = param["id"] as number;

      if (pk !== undefined) {
        this.tasks.read(pk).subscribe((task) => {
          this.task = task;


        });
      }
    });
  }
  getAuth() {
    this.users.info().subscribe(profile => {
    })
  }
  public todayTask(scheduler: MachineScheduler[]) {
    scheduler.forEach(schedule => {
      schedule.timedTaskList.forEach(task => {
        console.log(task)

        task.repeat.forEach(day => {
          if (day == this.currenteDate.getDay()) {
            this.tasksToday.data.push(task);
          }
        })
        this.tasksToday.data = this.tasksToday.data.filter(task => task.machineTask.type == "MANUAL");
        this.tasksToday.data = this.orderByStartTime(this.tasksToday.data);
        this.executedTasks = this.tasksToday.data;
        this.backupData = this.tasksToday.data;
        this.getMaps();
      })
    })
  }
  public removeTaskNotExecuted(element: TimedTask, value: MatCheckboxChange) {
    if (!value.checked) {
      this.executedTasks = this.executedTasks.filter(timedTask => timedTask.id !== element.id);
    }
    if (value.checked) {
      this.executedTasks.push(element)
    }

  }


  public removeTask(scheduler: MachineScheduler[]) {
    scheduler.forEach(schedule => {
      schedule.timedTaskList.forEach(task => {
        task.repeat.forEach(day => {
          if (day == this.currenteDate.getDay()) {
            this.tasksToday.data.push(task);
          }
        })
        this.tasksToday.data = this.tasksToday.data.filter(task => task.machineTask.type == "MANUAL")
      })
    })
  }
  public orderByStartTime(timedTasks: TimedTask[]): TimedTask[] {
    return timedTasks.sort((a, b) => {
      // Converte o horário no formato "HH:mm:ss" para segundos
      const horaParaSegundos = (hora: string): number => {
        const [h, m, s] = hora.split(":").map(Number);
        return h * 3600 + m * 60 + s;
      };

      const segundosA = horaParaSegundos(a.startTime);
      const segundosB = horaParaSegundos(b.startTime);

      return segundosA - segundosB; // Ordena em ordem crescente
    });
  }

  public finish() {
    this.machineExecuted.data = [];
    this.finished = !this.finished
  }

  @ViewChild(MatPaginator)
  set paginator(pager: MatPaginator) {
    this.tasksToday.paginator = pager;
  }


}
