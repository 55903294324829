<div *ngIf="datasource.data ; else spinner" class="instance-list">
    <table mat-table [dataSource]="datasource">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>
                Nome
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element.name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="model">
            <th mat-header-cell *matHeaderCellDef>
                Modelo
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element.model.maker }} &mdash; {{ element.model.model }}
            </td>
        </ng-container>

        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef>
              Localização
          </th>
          <td mat-cell *matCellDef="let element">
              {{ element.location.name }}
          </td>
      </ng-container>
        <ng-container matColumnDef="actions">
            <th class="instances-actions" mat-header-cell *matHeaderCellDef>
                Ações
            </th>
            <td class="instances-actions" mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="editMachine(element)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button (click)="deleteMachine(element)">
                    <mat-icon>delete</mat-icon>
                </button>

            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="fields"></tr>
        <tr mat-row *matRowDef="let row; columns: fields;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons
        aria-label="Selecionar quantidade de elementos na página">
    </mat-paginator>


    <button class="alabia-fab" mat-fab (click)="editMachine()">
        <mat-icon>add</mat-icon>
    </button>
</div>

<ng-template #spinner>
    <mat-spinner class="alabia-view-spinner"></mat-spinner>
</ng-template>
