<div *ngIf="!finished" class="task-list">


  <div id="form-field">
    <mat-form-field appearance="outline" color="accent" style="padding-right: 10px; width: 200px">
      <mat-label>Local</mat-label>
      <mat-select (selectionChange)="filterByMap($event.value)">
        <mat-option *ngFor="let map of machineMaps" value="{{map.id}}">{{map.title}} </mat-option>
        <mat-option value="{{0}}">Todos</mat-option>

      </mat-select>
    </mat-form-field>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="!dataLoaded"></mat-progress-bar>
  <table mat-table [dataSource]="tasksToday" >

    <ng-container matColumnDef="checked">
      <th mat-header-cell *matHeaderCellDef>
        Checked
      </th>
      <td mat-cell *matCellDef="let element">
        <section class="example-section">
          <mat-checkbox [checked]="true" class="example-margin"
            (change)="removeTaskNotExecuted(element, $event)"></mat-checkbox>
        </section>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>
        Nome
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.machineTask.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>
        Tipo
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.machineTask.type || 'Recorrente' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="start">
      <th mat-header-cell *matHeaderCellDef>
        Início
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.startTime }}
      </td>
    </ng-container>
    <ng-container matColumnDef="map">
      <th mat-header-cell *matHeaderCellDef>
        Mapa
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.machineTask.map.title }}
      </td>
    </ng-container>
    <ng-container matColumnDef="tarefa">
      <th mat-header-cell *matHeaderCellDef>
        Tarefa
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.machineTask.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        Ações
      </th>
      <td mat-cell *matCellDef="let element">
        <a mat-icon-button [routerLink]="['/cleaning/tasks/',element.machineTask.id]"
          (click)="setScheduleInfo(element)">
          <mat-icon>add_circle_outline</mat-icon>
        </a>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="fields"></tr>
    <tr mat-row *matRowDef="let row; columns: fields;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[50, 100]" showFirstLastButtons
    aria-label="Selecionar quantidade de elementos na página">
  </mat-paginator>


  <mat-card-actions>
    <button mat-raised-button color="accent" (click)="create()">Registrar</button>
  </mat-card-actions>
</div>


<ng-template #spinner>
  <mat-spinner class="alabia-view-spinner"></mat-spinner>
</ng-template>

<div *ngIf="finished" class="task-list">
  <table mat-table [dataSource]="machineExecuted">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>
        Nome
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.task.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="map">
      <th mat-header-cell *matHeaderCellDef>
        Mapa
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.task.map.title }}
      </td>
    </ng-container>

    <ng-container matColumnDef="operator">
      <th mat-header-cell *matHeaderCellDef>
        Operador
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.operator}}
      </td>
    </ng-container>

    <ng-container matColumnDef="start">
      <th mat-header-cell *matHeaderCellDef>
        Início
      </th>
      <td mat-cell *matCellDef="let element">
        {{ toDate(element.start) | date: 'short'  }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="executedFields"></tr>
    <tr mat-row *matRowDef="let row; columns: executedFields;"></tr>
  </table>
  <button style="margin-top: 10p;" mat-raised-button color="accent" (click)="finish()">Voltar</button>
</div>


