<form [formGroup]="form" (ngSubmit)="submitTask()">
  <h3 mat-dialog-title>
    {{ title }}
  </h3>

  <mat-dialog-content class="mat-typography">
    <div *ngIf="mapList; else spinner" class="task-list">
      <mat-form-field appearance="outline">
        <mat-label>Nome</mat-label>
        <input matInput type="text" formControlName="name" />
        <mat-hint>Dê um nome à sua tarefa</mat-hint>
      </mat-form-field>


      <mat-form-field appearance="outline">
        <mat-label>Descrição</mat-label>
        <textarea matInput formControlName="description" rows="8"></textarea>
        <mat-hint>Markdown com uma descrição da tarefa</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Tipo</mat-label>

        <mat-select formControlName="type" (selectionChange)="selectType($event.value)">
          <mat-option *ngFor="let type of typeList" [value]="type">
            {{ type }}
          </mat-option>

        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Mapa</mat-label>
        <mat-select formControlName="map" [compareWith]="compareMap">
          <mat-option *ngFor="let map of mapList" [value]="map">
            {{ map.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <form [formGroup]="clForm" (ngSubmit)="addChecklist()" *ngIf="data.id !== undefined">
        <mat-list *ngIf="checklist !== undefined && checklist.length > 0 && typeSelected=='MANUAL'">
          <div mat-subheader>Checklist</div>
          <mat-list-item *ngFor="let item of checklist">
            <button mat-icon-button type="button" (click)="deleteChecklist(item)">
              <mat-icon>delete</mat-icon>
            </button>
            {{ item.content }}
            {{ item.area }} m<sup>2</sup>
            {{ item.duration/60 }} min

          </mat-list-item>
        </mat-list>
        <mat-divider></mat-divider>
        <mat-form-field appearance="outline" style="margin-top: 16px;" *ngIf="typeSelected=='MANUAL'">
          <mat-label>Item</mat-label>
          <input matInput type="text" formControlName="content" />

        </mat-form-field>
        <div style="display: flex; gap: 16px; margin-top: 5px;">

          <mat-form-field appearance="outline" *ngIf="typeSelected=='MANUAL'">
            <mat-label>Área em m²</mat-label>
            <input matInput type="number" formControlName="area" />

          </mat-form-field>
          <mat-form-field appearance="outline"  *ngIf="typeSelected=='MANUAL'">
            <mat-label>Duração em minutos</mat-label>
            <input matInput type="number" formControlName="duration" />
          </mat-form-field>
        </div>
        <mat-list *ngIf="checklist !== undefined && checklist.length > 0 && typeSelected == 'MAINTENANCE'">
          <div mat-subheader>Checklist</div>
          <mat-list-item *ngFor="let item of checklist">
            <button mat-icon-button type="button" (click)="deleteChecklist(item)">
              <mat-icon>delete</mat-icon>
            </button>
            {{ item.machine.nickName}}
          </mat-list-item>
        </mat-list>
        <mat-form-field appearance="outline" style="margin-top: 16px;" *ngIf="typeSelected == 'MAINTENANCE'">
          <mat-label>Robô: </mat-label>
          <mat-select formControlName="machine" [compareWith]="compareMap">
            <mat-option *ngFor="let machine of machines" [value]="machine">
              {{ machine?.nickName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-raised-button color="accent" type="submit">
          Adicionar
        </button>
      </form>

    </div>

    <ng-template #spinner>
      <mat-spinner class="alabia-view-spinner"></mat-spinner>
    </ng-template>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="warn" [mat-dialog-close]="false">Cancelar</button>

    <button mat-raised-button color="accent" type="submit">Salvar</button>
  </mat-dialog-actions>
</form>
