import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Machine, MachineModel, MachineModelService, MapUnit, MapUnitService } from 'onevoice';
import * as uuid from 'uuid';

@Component({
  selector: 'main-edit-machines',
  templateUrl: './edit-machines.component.html',
  styleUrls: ['./edit-machines.component.scss']
})
export class EditMachinesComponent {
  public form: FormGroup;
  public modelList?: MachineModel[];
  public unitList? : MapUnit[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Machine,
    private reference: MatDialogRef<EditMachinesComponent, Machine>,

    public models: MachineModelService,
    public mapUnitList: MapUnitService,
  ) {
    this.form = new FormGroup({
      "name": new FormControl(data.name || "", [Validators.required]),
      "uniqueId": new FormControl(data.uniqueId || uuid.v4(), [Validators.required]),
      "model": new FormControl(data.model || undefined, [Validators.required]),
      "location": new FormControl(data.location || undefined, [Validators.required]),
    }, []);
    this.models.list().subscribe(modelList => {
      this.modelList = modelList;
    });
    this.getUnits();
  }

  get title(): string {
    return (this.data.id !== undefined) ? "Editar Máquina" : "Criar Máquina";
  }


  public getUnits(){
    this.mapUnitList.list().subscribe(data =>{
      this.unitList = data;
    })
  }
  public submitMachine(): void {
    this.reference.close({
      ...this.data,
      ...this.form.value,
    })
  }

  public compareModel(m1: MachineModel, m2: MachineModel) {
    return m1.id == m2.id;
  }
}
